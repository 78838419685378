<template>
  <v-container class="mt-4 px-5" fluid>
    <!--  -->
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title class="text-subtitle-1">
            Teachers Mode
            <v-spacer></v-spacer>

            <!-- Boton para descargar el excel -->
            <v-btn v-if="[57, 52, 11, 12].includes(getdatosUsuario.idpuesto)" class="mr-2" small dark color="green" @click="exportExcel(filterDisponibilidad, 'Horarios')" tile>
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <!-- Administrar cuentas -->
            <v-btn v-if="[57, 52, 11, 12].includes(getdatosUsuario.idpuesto)" class="mr-2" small dark color="orange" @click="dialogSalonesVirtuales = true" tile>
              <v-icon small>mdi-plus</v-icon>
              Salones
            </v-btn>

            <!-- Administrar cuentas -->
            <v-btn v-if="[57, 52, 11, 12].includes(getdatosUsuario.idpuesto)" class="mr-2" small dark color="orange" @click="dialogHorariosVirtuales = true" tile>
              <v-icon small>mdi-plus</v-icon>
              Horarios
            </v-btn>

            <v-btn color="primary" @click="initialize()" small tile>
              <v-icon small left>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field filled single-line type="date" hide-details v-model="fechaConsultar"
                  dense clearable 
                ></v-text-field>
              </v-col>
            </v-row>

            <br/>

            <v-row>
              <v-col cols="12" sm="2" v-if="[57, 52, 11, 12].includes(getdatosUsuario.idpuesto)">
                <v-btn class="mr-2" small dark color="black" tile @click="dialogInhabilitarSalon = true">
                  Inhabilitar salon
                </v-btn>
              </v-col>
              <v-col cols="12" sm="2">
                <v-btn class="mr-2" small dark color="orange" tile @click="dialogSolicitarSalon = true">
                  <v-icon small>mdi-plus</v-icon>
                  Reservar Salon
                </v-btn>
              </v-col>
              <v-col cols="12" sm="2">
                <v-btn class="mr-2" small dark color="red" tile @click="dialogCancelarReserva = true">
                  Cancelar reserva
                </v-btn>
              </v-col>
            </v-row>

            <br/> <br/>

            <!-- <v-row>
              <v-col v-for="(salon, i) in salones" :key="i">
                <v-btn class="mr-2" small dark color="primary" tile>
                  Ir a: {{ salon.nombre_salon }}
                </v-btn>
              </v-col>
            </v-row> -->

            <!-- REQUISICIONES -->
            <v-data-table
              :headers="headers"
              :items="horariosTabla"
              dense
              class="elevation-0 mt-4"
              :search="buscar"
              item-class="clase_row"
            >

            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogo de editar o agregar-->
	  <v-dialog v-model="dialogSolicitarSalon" max-width="400px" persistent  @click:outside="closeDialog" @keydown.esc="closeDialog">
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">Solicitar salon</span>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text>
      		<label>Elige un salon: </label>
      		<v-select :items="salones" v-model="editedItem.salon" 
            item-text="nombre_salon" item-value="idsalones_virtuales" filled dense single-line>
          </v-select>

          <label>Elige un horario: </label>
      		<v-select :items="horarios" v-model="editedItem.horario" 
            item-text="hora" item-value="idsalones_virtuales_horarios" filled dense single-line>
          </v-select>
        </v-card-text>

        <v-card-actions>
          <v-btn color="black" dark small @click="closeDialog" tile dense>
          	<v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" small dark @click="guardarSolicitarSalon" tile>
          	<v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo de cancelar la reserva del salon -->
	  <v-dialog v-model="dialogCancelarReserva" max-width="600px" persistent @click:outside="closeDialog" @keydown.esc="closeDialog">
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">Cancelar reserva de salon</span>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogCancelarReserva = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text>
      		<v-data-table :items="filterDisponibilidad" :headers="headersDisp" class="elevation-0" :mobile-breakpoint="100" dense>
            <template v-slot:item.nombre_salon="{ item }">
                {{ salones.find(el=>el.idsalones_virtuales == item.idsalon_virtual).nombre_salon }}
            </template>

            <template v-slot:item.actions="{ item }">
					    	<v-btn color="error" x-small @click="deleteItem(item)" class="mr-2">
						      <v-icon small> mdi-delete</v-icon>
					    	</v-btn>
					    </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <!-- <v-btn color="black" dark small @click="dialogCancelarReserva = false" tile dense>
          	<v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" small dark @click="guardarSolicitarSalon" tile>
          	<v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo de cancelar la reserva del salon -->
	  <v-dialog v-model="dialogInhabilitarSalon" max-width="600px" persistent @click:outside="closeDialog" @keydown.esc="closeDialog">
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">Inhabilitar salon</span>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text>
          <h4>Salones no disponibles</h4> <br>
          <v-data-table :items="filterSalonesInhabilitados" :headers="headersSalonesInhabilitados">
            <template v-slot:item.actions="{ item }">
              <v-btn color="error" x-small @click="deleteItem(item)" class="mr-2">
                <v-icon small> mdi-delete</v-icon>
              </v-btn>
            </template>

            <template v-slot:item.nombre_salon="{ item }">
                {{ salones.find(el=>el.idsalones_virtuales == item.idsalon_virtual).nombre_salon }}
            </template>
          </v-data-table>

          <br>
          <h4>Agregar salon no disponible</h4> <br>
      		<label>Elige un salon: </label>
      		<v-select :items="salones" v-model="editedItem.salon" 
            item-text="nombre_salon" item-value="idsalones_virtuales" filled dense single-line>
          </v-select>

          <label>Elige un horario: </label>
      		<v-select :items="horarios" v-model="editedItem.horario" 
            item-text="hora" item-value="idsalones_virtuales_horarios" filled dense single-line>
          </v-select>
        </v-card-text>

        <v-card-actions>
          <v-btn color="black" dark small @click="closeDialog" tile dense>
          	<v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" small dark @click="guardarInhabilitarSalon" tile>
          	<v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title></v-card-title>
      	<v-card-text>
	      	<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
            <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar los datos?
          </div>
      	</v-card-text>
        <v-card-actions>
          <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
          <v-btn color="error" text  @click="closeDialogDelete()">No, cancelar</v-btn>
          <v-spacer></v-spacer>
          <!-- Guardar la información  -->
          <v-btn color="primary"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SalonesVirtuales :isOpen="dialogSalonesVirtuales" @getSalones="getSalones" @closeDialog="closeDialog"/>

    <HorariosVirtuales :isOpen="dialogHorariosVirtuales" @getHorarios="getHorarios" @closeDialog="closeDialog" />

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";

import { Clipboard } from "@capacitor/clipboard";

// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import SalonesVirtuales    from "@/components/academico/teachersmode/SalonesVirtuales"
import HorariosVirtuales  from "@/components/academico/teachersmode/HorariosVirtuales"

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  components: {
    Alerta,
    carga,
    SalonesVirtuales,
    HorariosVirtuales
  },

  mixins: [validarErrores, funcionesExcel],

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    buscar:'',
    
    fechaConsultar : new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    disponibilidad : [],
    salones : [],
    horarios : [],
    horariosTabla: [],
    respuestaAlerta: false,
    cargar: false,
    
    editedIndex: -1,
    dialogSalonesVirtuales: false,
    dialogHorariosVirtuales: false,
    dialogSolicitarSalon: false,
    dialogCancelarReserva: false,
    dialogInhabilitarSalon: false,
    dialogDelete: false,

    headers: [
      { text: "", value: "hora_inicio", sortable : false},
    ],
    headersSalonesInhabilitados: [
      { text: "Hora inicio", value: "hora_inicio_edit"},
      { text: "Hora final", value: "hora_final_edit"},
      { text: "Salon", value: "nombre_salon"},
      { text: "Acciones", value: "actions"},
    ],
    headersDisp: [
      { text: "Teacher", value: "nombre_completo"},
      { text: "Hora inicio", value: "hora_inicio_edit"},
      { text: "Hora final", value: "hora_final_edit"},
      { text: "Salon", value: "nombre_salon"},
      { text: "Acciones", value: "actions"},
    ],

    editedItem : {
      salon : 0,
      horario : 0,
    },
    defaultItem : {
      salon : 0,
      horario : 0,
    }
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),

    filterDisponibilidad(){
      let disponibilidad = this.disponibilidad;
      disponibilidad = disponibilidad.filter(el=>el.estatus_salon == 1)
      if(![57, 52, 11, 12].includes(this.getdatosUsuario.idpuesto)){
        disponibilidad = disponibilidad.filter(el=>el.teacher_asignado == this.getdatosUsuario.iderp)
      }
      return disponibilidad;
    },

    filterSalonesInhabilitados(){
      let disponibilidad = this.disponibilidad;
      disponibilidad = disponibilidad.filter(el=>el.estatus_salon == 2)
      return disponibilidad;
    }
  },

  watch:{
    fechaConsultar( value ){
      if( value ){ 
        this.initialize()
      }
    }
  },

  async created() {
    this.initialize()
  },

  methods: {
    async initialize() { 
      this.cargar = true
      await this.getDisponibilidad()
      this.cargar = false
      console.log(this.getdatosUsuario)
    },

    getDisponibilidad(){
      return this.$http.get('teachersmode.get.dispo/' + this.fechaConsultar).then(response=>{
        this.disponibilidad = response.data.disponibilidad
        this.salones = response.data.salones
        this.setHeaders()
        
        for (const disp of this.disponibilidad) {
          disp.hora_inicio_edit = this.formatTime24to12(disp.hora_inicio)
          disp.hora_final_edit = this.formatTime24to12(disp.hora_final)
        }
        let horariosAux = []
        horariosAux = response.data.horarios
        for (const horario of horariosAux) {
          horario.hora_inicio_edit = this.formatTime24to12(horario.hora_inicio)
          horario.hora_final_edit = this.formatTime24to12(horario.hora_final)
          horario.hora = horario.hora_inicio_edit + ' - ' + horario.hora_final_edit
          for (const salon of this.salones) {
            let disp = this.disponibilidad.find(element=>element.idsalon_virtual == salon.idsalones_virtuales && element.idsalon_virtual_horario == horario.idsalones_virtuales_horarios)
            horario[salon.columna] = disp ? disp.estatus_salon == 1 ? disp.nombre_completo : disp.estatus_salon == 2 ? 'No disponible' : '' : ''
          }
        }
        let payload = [
          {hora_inicio : 'Codigo'},
          {hora_inicio : 'Contraseña'},
          {hora_inicio : 'Link'}
        ]
        this.salones.forEach(el=>{
          payload[0][el.columna] = el.codigo
          payload[1][el.columna] = el.password
          payload[2][el.columna] = el.link
        })
        this.horarios = horariosAux
        this.horariosTabla = payload.concat(horariosAux)
      }).catch( error =>{
        this.validarError( error )
      }).finally( () => { })
    },

    getSalones(){
      this.salones = []
      return this.$http.get('teachersmode.get.salones').then(response=>{
        this.salones = response.data.salones
        this.setHeaders()
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { })
    },

    getHorarios(){
      this.horarios = []
      return this.$http.get('teachersmode.get.horarios/'+ this.fechaConsultar).then(response=>{
        let horariosAux = []
        horariosAux = response.data.horarios
        for (const horario of horariosAux) {
          horario.hora_inicio_edit = this.formatTime24to12(horario.hora_inicio)
          horario.hora_final_edit = this.formatTime24to12(horario.hora_final)
          horario.hora = horario.hora_inicio_edit + ' - ' + horario.hora_final_edit
          for (const salon of this.salones) {
            let disp = this.disponibilidad.find(element=>element.idsalon_virtual == salon.idsalones_virtuales && element.idsalon_virtual_horario == horario.idsalones_virtuales_horarios)
            horario[salon.columna] = disp ? disp.estatus_salon == 1 ? disp.nombre_completo : disp.estatus_salon == 2 ? 'No disponible' : '' : ''
          }
        }
        let payload = [
          {hora_inicio : 'Codigo'},
          {hora_inicio : 'Contraseña'},
          {hora_inicio : 'Link'}
        ]
        this.salones.forEach(el=>{
          payload[0][el.columna] = el.codigo
          payload[1][el.columna] = el.password
          payload[2][el.columna] = el.link
        })
        this.horarios = horariosAux
        this.horariosTabla = payload.concat(horariosAux)
      }).catch( error =>{
        this.validarError( error)
      }).finally( () => { this.cargar = false })
    },

    setHeaders(){
      this.headers = [
      { text: "", value: "hora_inicio", sortable: false},
      ]
      console.log(this.salones)
      this.salones.forEach(element => {
        element.texto = element.nombre_salon //+ ' - '  + element.codigo + ' - ' + element.password + ' - ' + element.link
        element.columna = element.nombre_salon.replace(/ /g, "");
        let payload = {
          text : element.texto,
          value : element.columna,
          sortable : false
        }
        this.headers.push(payload)
      });
    },

    guardarSolicitarSalon(){
      let payload = {
        salon : this.editedItem.salon,
        horario : this.editedItem.horario,
        estatus : 1,
        teacher : this.getdatosUsuario.iderp,
        usuario_modificacion : this.getdatosUsuario.iderp,
      }
      if(this.editedItem.salon != 0 && this.editedItem.horario != 0){
        this.$http.post('teachersmode.add.dispo', payload).then(response=>{
          this.closeDialog()
          this.getDisponibilidad() 
          this.validarSuccess(response.data.message)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      } else {
        this.validarErrorDirecto("Datos vacios")
      }
    },

    guardarInhabilitarSalon(){
      let payload = {
        salon : this.editedItem.salon,
        horario : this.editedItem.horario,
        estatus : 2,
        teacher : 0,
        usuario_modificacion : this.getdatosUsuario.iderp,
      }
      if(this.editedItem.salon != 0 && this.editedItem.horario != 0){
        this.$http.post('teachersmode.add.dispo', payload).then(response=>{
          this.closeDialog()
          this.getDisponibilidad()
          this.validarSuccess(response.data.message)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      } else {
        this.validarErrorDirecto("Datos vacios")
      }
    },

    closeDialog(){
      this.dialogSalonesVirtuales = false
      this.dialogHorariosVirtuales = false
      this.dialogCancelarReserva = false
      if(this.dialogSolicitarSalon){
        this.editedItem = this.defaultItem
        this.dialogSolicitarSalon = false
      }
      if(this.dialogInhabilitarSalon){
        this.editedItem = this.defaultItem
        this.dialogInhabilitarSalon = false
      }
    },

    closeDialogDelete(){
      this.editedItem = this.defaultItem
      this.dialogDelete = false
    },

    deleteItem (item) {
      this.editedIndex = this.disponibilidad.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.cargar = true
      console.log(this.editedItem)
      let payload = {
        salon : this.editedItem.idsalon_virtual,
        horario : this.editedItem.idsalones_virtuales_horarios,
        estatus : 0,
        teacher : 0,
        usuario_modificacion : this.getdatosUsuario.iderp,
      }
      console.log( this.editedItem)
      this.$http.post('teachersmode.update.dispo/', payload).then(response=>{
        this.closeDialogDelete()
        this.getDisponibilidad()
      }).catch( error =>{ 
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },
    
    formatTime24to12(time) {
      // Dividir la hora en horas y minutos
      let [hour, minute] = time.split(':');

      // Convertir las partes a números
      hour = parseInt(hour, 10);
      minute = parseInt(minute, 10);

      // Determinar el sufijo AM o PM
      const ampm = hour >= 12 ? 'PM' : 'AM';

      // Convertir la hora de 24 horas a 12 horas
      hour = hour % 12;
      hour = hour ? hour : 12; // La hora '0' debe ser '12'

      // Añadir ceros delante si es necesario
      const strHour = hour.toString().padStart(2, '0');
      const strMinute = minute.toString().padStart(2, '0');

      // Devolver la hora formateada
      return `${strHour}:${strMinute} ${ampm}`;
    }
  },
};
</script>
