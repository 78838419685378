<template>
  <v-dialog persistent v-model="isOpen" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="1100">
    <v-card class="shadowCard">
		  <v-card-title primary-title>
		    <span class="text-subtitle-1">Administrar Salones</span>
		    <v-spacer></v-spacer>
        <v-btn class="mr-2" small dark color="green" @click="exportExcel( salones  , 'Salones')" tile>
          <v-icon small>mdi-microsoft-excel</v-icon>
        </v-btn>

        <v-btn color="orange" dark class="mr-2" @click="dialog = true" small tile>
          <v-icon small>mdi-plus</v-icon>
        </v-btn>

        <v-btn color="primary" dark class="text-capitalize" @click="getSalones()" small tile>
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        
		  </v-card-title>

		  <v-card-text>
		  	<v-row justify="end">
		  		<v-col cols="12" md="10">
		  			<v-text-field name="name" label="Buscar" id="id" filled dense single-line hide-details append-icon="mdi-magnify" v-model="search">
		  			</v-text-field>
		  		</v-col>
		  	</v-row>
		    <v-row>
		    	<v-col cols="12">
					   <v-data-table
					    :headers="headers"
					    :items="salones"
					    class="elevation-0"
					    :search="search"
					    :mobile-breakpoint="100"
	            dense
					  >

					  	<template v-slot:item.escuela="{ item }">
					    	<v-chip color="#44A7FF" small class="mr-2" v-if="item.escuela == 1" dark>
						      INBI
					    	</v-chip>

					    	<v-chip color="#000B58" small class="mr-2" v-if="item.escuela == 2" dark>
					    		FAST
					    	</v-chip>
					    </template>

					    <template v-slot:item.actions="{ item }">
					    	<v-btn color="primary" x-small @click="editItem(item)" class="mr-2">
						      <v-icon small>mdi-magnify</v-icon>
					    	</v-btn>

					    	<v-btn color="error" x-small @click="deleteItem(item)" class="mr-2">
						      <v-icon small> mdi-delete</v-icon>
					    	</v-btn>
					    </template>

					    <template v-slot:no-data>
					      <v-btn color="primary" @click="getSalones" small >
					        Actualizar
					      </v-btn>
					    </template>

					  </v-data-table> 
		    	</v-col>
		    </v-row>
		  </v-card-text>
		  <v-card-actions>
		  	<v-btn color="black" dark small tile @click="closeDialog">
		  		<v-icon small left>mdi-close</v-icon>
		  		Cerrar
		  	</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
		</v-card>

    <!-- Dialogo de editar o agregar-->
	  <v-dialog v-model="dialog" max-width="400px" persistent >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idsalones_virtuales }}</strong>
          </span>
        </v-card-title>

        <v-card-text>

      		<label>Elige una escuela: </label>
      		<v-select :items="[{id: 1, text: 'INBI'}, {id: 2, text: 'FAST'}]" v-model="editedItem.escuela" 
            label="Escuela" item-text="text" item-value="id" filled dense single-line>
          </v-select>

      		<label>Nombre: </label>
          <v-text-field v-model="editedItem.nombre_salon" label="Salón" filled single-line dense></v-text-field>

         	<label>Contraseña: </label>
          <v-text-field v-model="editedItem.password" label="Contraseña" filled single-line dense></v-text-field>

          <label>Código: </label>
          <v-text-field v-model="editedItem.codigo" label="Codigo" filled single-line dense></v-text-field>

          <label>Link: </label>
          <v-text-field v-model="editedItem.link" label="Link" filled single-line dense></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-btn color="black" dark small @click="close" tile dense>
          	<v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" small dark @click="save" tile>
          	<v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title></v-card-title>
      	<v-card-text>
	      	<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
            <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar los datos?
          </div>
      	</v-card-text>
        <v-card-actions>
          <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
          <v-btn color="error" text  @click="closeDelete()">No, cancelar</v-btn>
          <v-spacer></v-spacer>
          <!-- Guardar la información  -->
          <v-btn color="primary"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {

  mixins: [ validarErrores, funcionesExcel ],

  props: ['isOpen'],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      editedItem: {
      	idsalones_virtuales:0,
        escuela:0,
				nombre_salon:'',
        password:'',
        codigo:'',
        link:'',
				deleted:0,
				fecha_creacion: null,
      },

      defaultItem: {
      	idsalones_virtuales:0,
        escuela:0,
				nombre_salon:'',
        password:'',
        codigo:'',
        link:'',
				deleted:0,
				fecha_creacion: null,
      },

			search:'',
      salones: [],
      headers: [
        { text: 'ID'          , value: 'idsalones_virtuales'  },
        { text: 'Escuela'     , value: 'escuela'              },
        { text: 'Salón'       , value: 'nombre_salon'         },
        { text: 'Código'      , value: 'codigo'               },
        { text: 'Contraseña'  , value: 'password'             },
        { text: 'Link'        , value: 'link'                 },
        { text: 'Actions'     , value: 'actions', sortable: false },
      ],
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar salón' : 'Editar salón'
      },
    },

    watch: {

    },

    async created () {
      await this.initialize()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.getSalones()
      },

      getSalones(){
        this.salones = []
        return this.$http.get('teachersmode.get.salones').then(response=>{
        	this.salones = response.data.salones
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.salones.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.salones.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        this.editedItem.deleted = 1
        this.$http.put('teachersmode.update.salon/' + this.editedItem.idsalones_virtuales, this.editedItem).then(response=>{
        	this.closeDelete()
        	this.getSalones()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDialog(){
        this.$emit('closeDialog');        
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
      	//Cargamos al usuario que hizo el cambio
	      this.cargar = true

        if(this.editedItem.escuela && this.editedItem.nombre_salon && this.editedItem.password && this.editedItem.codigo && this.editedItem.link){
          if (this.editedIndex > -1) {
            //Lo mandapos por el EP
            this.$http.put('teachersmode.update.salon/' + this.editedItem.idsalones_virtuales, this.editedItem).then(response=>{
              this.validarSuccess( response.data.message )
              this.$emit('getSalones');     
              this.getSalones()
              this.close()
            }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
  
          } else {
            //Lo mandapos por el EP
            this.$http.post('teachersmode.add.salon', this.editedItem).then(response=>{
              this.getSalones()
              this.$emit('getSalones');  
              this.close()
              this.cargar = false
            }).catch( error =>{
              this.validarError( error.response.data.message )
            }).finally( () => { this.cargar = false })
          }
        } else {
          this.validarErrorDirecto("Datos vacios")
        }

      },
    },
  }
</script>
