<template>
  <v-dialog persistent v-model="isOpen" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="600">
    <v-card class="shadowCard">
		  <v-card-title primary-title>
		    <span class="text-subtitle-1">Editar Horarios</span>
		    <v-spacer></v-spacer>
        
        <v-btn class="mr-2" small dark color="green" @click="exportExcel( horarios  , 'Horarios')" tile>
          <v-icon small>mdi-microsoft-excel</v-icon>
        </v-btn>

        <v-btn color="orange" dark class="mr-2" @click="dialogAdd = true" small tile>
          <v-icon small>mdi-plus</v-icon>
        </v-btn>

        <v-btn color="primary" dark class="text-capitalize" @click="getHorarios()" small tile>
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
		  </v-card-title>

		  <v-card-text>
        <v-row>
          <v-col>
            <v-text-field filled single-line type="date" hide-details v-model="fechaConsultarHorarios" dense clearable></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headersVerEdit" :items="horarios" class="elevation-0" :mobile-breakpoint="100" dense>
              <template v-slot:item.actions="{ item }">
					    	<v-btn color="primary" x-small @click="editItem(item)" class="mr-2">
						      <v-icon small>mdi-magnify</v-icon>
					    	</v-btn>

					    	<v-btn color="error" x-small @click="deleteItem(item)" class="mr-2">
						      <v-icon small> mdi-delete</v-icon>
					    	</v-btn>
					    </template>
            </v-data-table>
          </v-col>
        </v-row>
		  </v-card-text>

		  <v-card-actions>
		  	<v-btn color="black" dark small tile @click="closeDialog">
		  		<v-icon small left>mdi-close</v-icon>
		  		Cerrar
		  	</v-btn>
      </v-card-actions>
		</v-card>

    <!-- Dialogo de editar o agregar-->
	  <v-dialog v-model="dialogAdd" max-width="500px" persistent >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">Agregar horario</span>
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mx-2" @click="dialogHorarios" small tile v-bind="attrs" v-on="on">
              <v-icon small>mdi-transfer-down</v-icon>
            </v-btn>
          </template>
          <span>Copiar horarios de otro día</span>
        </v-tooltip>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="closeDialogEditAddDelete"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field filled single-line type="date" hide-details v-model="fechaAgregarHorarios" dense clearable @change="getHorarios"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-data-table :items="arregloHorarios" :headers="headersAgregar" hide-default-footer>
                <template v-slot:item.actions="{item}">
                  <v-icon small @click="deleteHorarioArray(item)" color="primary"> mdi-delete </v-icon>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" md="5" class="pb-0">
              <label>Hora inicio</label>
              <v-text-field filled single-line label="Hora inicio" type="time" hide-details v-model="editedItem.hora_inicio" dense clearable></v-text-field>
            </v-col>
            <v-col cols="12" md="5" class="pb-0">
              <label>Hora final</label>
              <v-text-field filled single-line label="Hora final" type="time" hide-details v-model="editedItem.hora_final" dense clearable></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="pb-2 " align-self="end">
              <v-btn color="orange" dark class="mr-2" @click="addHorarioArray(editedItem)" small tile>
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <br>
        </v-card-text>

        <v-card-actions>
          <v-btn color="black" dark small @click="closeDialogEditAddDelete" tile dense>
          	<v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" small dark @click="save" tile>
          	<v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTransferirHorario" max-width="500px" persistent >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">Transferir horario</span>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogTransferirHorario = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field filled single-line type="date" hide-details v-model="fechaTransferirHorarios"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-data-table :items="transferirHorarios" :headers="headersVerTransfer" hide-default-footer>
                
              </v-data-table>
            </v-col>
          </v-row>
          <br>
        </v-card-text>

        <v-card-actions>
          <v-btn color="black" dark small @click="dialogTransferirHorario = false" tile dense>
          	<v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" small dark @click="confirmarTransferirHorario" tile>
          	<v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog para eliminar -->
    <v-dialog v-model="dialogEdit" max-width="500px">
      <v-card>
        <v-card-title></v-card-title>
      	<v-card-text>
	      	<v-row>
            <v-col cols="12" md="6" class="pb-0">
              <label>Hora inicio</label>
              <v-text-field filled single-line label="Hora inicio" type="time" hide-details v-model="editedItem.hora_inicio" dense clearable></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0">
              <label>Hora final</label>
              <v-text-field filled single-line label="Hora final" type="time" hide-details v-model="editedItem.hora_final" dense clearable></v-text-field>
            </v-col>
          </v-row>
      	</v-card-text>
        <v-card-actions>
          <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
          <v-btn color="error" text  @click="closeDialogEditAddDelete">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <!-- Guardar la información  -->
          <v-btn color="primary"  dark class="elevation-6"  @click="editItemConfirm()">Si</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title></v-card-title>
      	<v-card-text>
	      	<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
            <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar los datos?
          </div>
      	</v-card-text>
        <v-card-actions>
          <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
          <v-btn color="error" text  @click="closeDialogEditAddDelete()">No, cancelar</v-btn>
          <v-spacer></v-spacer>
          <!-- Guardar la información  -->
          <v-btn color="primary"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {

  mixins: [ validarErrores, funcionesExcel ],

  props: ['isOpen'],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      editedIndex: -1,
      dialogAdd:false,
      dialogEdit:false,
      dialogDelete:false,
      dialogTransferirHorario:false,
      respuestaAlerta:false,
      cargar: false,

      arregloHorarios:[], //son los horarios que se van a insertar
      transferirHorarios:[], //los horarios que resultan de consultar para transferir
      horarios: [], //los que se muestran en la ventana principal
      
      editedItem: {
      	idsalones_virtuales_horarios:0,
        hora_inicio:null,
				hora_final:null,
        dia:0,
        fecha_creacion:null,
				deleted:0
      },

      defaultItem: {
      	idsalones_virtuales_horarios:0,
        hora_inicio:null,
				hora_final:null,
        dia:0,
        fecha_creacion:null,
				deleted:0
      },
      
      fechaConsultarHorarios : new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      fechaAgregarHorarios: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      fechaTransferirHorarios: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      headersVerTransfer:[
        { text: 'Dia'         , value: 'dia'  },
        { text: 'Hora Inicio' , value: 'hora_inicio'  },
        { text: 'Hora Final'  , value: 'hora_final'  },
      ],
      headersVerEdit:[
        { text: 'Dia'         , value: 'dia'  },
        { text: 'Hora Inicio' , value: 'hora_inicio'  },
        { text: 'Hora Final'  , value: 'hora_final'  },
        { text: 'Acciones'    , value: 'actions'  },
      ],
      headersAgregar:[
        { text: 'Hora Inicio' , value: 'hora_inicio'  },
        { text: 'Hora Final'  , value: 'hora_final'  },
        { text: 'Acciones'    , value: 'actions'  },
      ],
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),

      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Horario' : 'Editar Horario'
      },

      filterSalones(){
        return this.salones.filter( el => el.escuela == this.escuela )
      }
    },

    watch: {
      fechaTransferirHorarios( value ){
        if( value ){ 
          this.getHorariosTransferir()
        }
      },
      fechaConsultarHorarios( value ){
        if( value ){ 
          this.initialize()
        }
      }
    },

    async created () {
      await this.initialize()
    },

    methods: {
      initialize () {
        this.getHorarios()
      },

      getHorarios(){
        this.cargar = true
        this.horarios = []
        this.$http.get('teachersmode.get.horarios/'+ this.fechaConsultarHorarios).then(response=>{
          this.horarios = response.data.horarios
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
      
      getHorariosTransferir(){
        this.cargar = true
        this.transferirHorarios = []
        this.$http.get('teachersmode.get.horarios/'+ this.fechaTransferirHorarios).then(response=>{
          this.transferirHorarios = response.data.horarios
          console.log(this.transferirHorarios)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      confirmarTransferirHorario(){
        this.transferirHorarios.forEach(item=>{
          let payload = {
            idsalones_virtuales_horarios: this.arregloHorarios.length == 0 ? 1 : this.arregloHorarios[this.arregloHorarios.length-1].idsalones_virtuales_horarios+1,
            hora_inicio:item.hora_inicio,
            hora_final:item.hora_final,
            dia:0,
            fecha_creacion:null,
            deleted:0
          }

          this.arregloHorarios.push(payload)
        })
        this.dialogTransferirHorario = false
        this.transferirHorarios= []
      },

      dialogHorarios(){
        this.getHorariosTransferir()
        this.dialogTransferirHorario = true;
      },

      closeDialog(){
        this.$emit('closeDialog');        
      },

      deleteHorarioArray(item){
        this.arregloHorarios = this.arregloHorarios.filter(el=>el.idsalones_virtuales_horarios != item.idsalones_virtuales_horarios)
      },

      addHorarioArray(item){
        let payload = {
          idsalones_virtuales_horarios: this.arregloHorarios.length == 0 ? 1 : this.arregloHorarios[this.arregloHorarios.length-1].idsalones_virtuales_horarios+1,
          hora_inicio:item.hora_inicio,
          hora_final:item.hora_final,
          dia:0,
          fecha_creacion:null,
          deleted:0
        }
        let noInsertar = false;
        if(item.hora_inicio >= item.hora_final){
          noInsertar = true;
        }

        this.arregloHorarios.forEach(horarioOld=>{
          var horaInicioA = new Date("1970-01-01T" + item.hora_inicio + "Z");
          var horaInicioB = new Date("1970-01-01T" + horarioOld.hora_inicio + "Z");
          var horaFinalB = new Date("1970-01-01T" + horarioOld.hora_final + "Z");
          if(horaInicioA >= horaInicioB && horaInicioA < horaFinalB){
            noInsertar = true;
          }
        })

        if(this.editedItem.hora_inicio!= null && this.editedItem.hora_final!= null && !noInsertar){
          this.arregloHorarios.push(payload)
          this.editedItem.hora_inicio = null
          this.editedItem.hora_final = null
        } else {
          this.validarErrorDirecto("Horario invalido o faltan datos")
        }
      },

      async save () {
        if(this.fechaAgregarHorarios && this.arregloHorarios.length>0){
          this.cargar = true;
          this.arregloHorarios.forEach(el=>{
            el.dia= this.fechaAgregarHorarios;
          })
          this.$http.post('teachersmode.add.horario', {horarios: this.arregloHorarios}).then(response=>{
            this.$emit('getHorarios'); 
            this.getHorarios()
            this.closeDialogEditAddDelete()
            this.cargar = false
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        } else {
          this.validarErrorDirecto('Informacion incompleta')
        }
      },

      editItem (item) {
        this.editedIndex = this.horarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogEdit = true
      },

      editItemConfirm () {
        console.log(this.editedItem)
        this.cargar = true
        this.$http.put('teachersmode.update.horario/' + this.editedItem.idsalones_virtuales_horarios, this.editedItem).then(response=>{
        	this.closeDialogEditAddDelete()
          this.$emit('getHorarios'); 
        	this.getHorarios()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      deleteItem (item) {
        this.editedIndex = this.horarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      closeDialogEditAddDelete () {
        this.dialogAdd = false
        this.arregloHorarios = []
        this.dialogEdit = false
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      deleteItemConfirm () {
      	this.cargar = true
        this.editedItem.deleted = 1
        this.$http.put('teachersmode.update.horario/' + this.editedItem.idsalones_virtuales_horarios, this.editedItem).then(response=>{
        	this.closeDialogEditAddDelete()
          this.$emit('getHorarios');  
        	this.getHorarios()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
    },
  }
</script>
